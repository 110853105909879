/*SignIn is the starting component for the front end.
 * UI and their dependencies should be loaded here.
 * The input will be checked by the server on it's local database before allowing access.
 * @author Adam Kale @ Pufferfish Limited.
 */
import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const defaultTheme = createTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#000000",
      dark: "#000000",
      contrastText: "#fff",
    },
  },
});
export default function SignIn({ setToken }) {
  const [status, setStatus] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  //Checks the server for the validity of the email and password provided.
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    axios({
      method: "GET",
      url:
        "/api/login?Email=" +
        data.get("email") +
        "&Password=" +
        data.get("password"),
    })
      .then(function (response) {
        if (response.data.DownloadAuthResponse === "true") {
          setToken(GetToken());
        } else {
          setStatusMessage(response.data.DownloadAuthResponse);
          setStatus(true);
        }
      })
      .catch(function (error) {
        console.error(error.response.data);
      });
  };

  //Gets a random string so every login is unique.
  function GetToken() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1 }} src="./Pufferfish icon black@2x.png"></Avatar>
          <Typography component="h1" variant="h5">
            PufferServices
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
          {status ? (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              <strong>{statusMessage}</strong>
            </Alert>
          ) : (
            <></>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
}
