/*App is the main component for the front end.
 * UI and their dependencies should be loaded here.
 * @author Adam Kale @ Pufferfish Limited.
 */
import React, { useEffect, useState } from "react";
import axios from "axios";
import "./App.css";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import Grid from "@mui/material/Grid";
import FolderIcon from "@mui/icons-material/Folder";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Login from "./SignIn";
import Paper from "@mui/material/Paper";
import Logs from "./LogGetter";

export default function App() {
  const [token, setToken] = useState();
  const [status, setStatus] = useState();
  const [lastCRMUpdate, setLastCRMUpdate] = useState("");
  const [lastWeatherUpdate, setLastWeatherUpdate] = useState("");
  const [lastPFSWUpdate, setLastPFSWpdate] = useState("");
  const [frontVersion, setVersion] = useState("");
  const [availableUpdates, setAvailableUpdates] = useState([""]);
  const [state, setState] = useState({
    left: false,
  });
  const theme = useTheme();
  const [currentInput, setInput] = useState(false);
  const [fileDownloadOpen, setDownloadOpen] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const [updateRequest, setCurrentDownloadRequest] = useState("");

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  const SetMenu = (index) => {
    switch (index) {
      case 0:
        DBUpdate();
        break;
      case 1:
        GetTestWeather();
        break;
      case 2:
        UpdateServerWeather();
        break;
      case 3:
        UpdatePFSoftware();
        break;
      default:
        return;
    }
  };

  const handleFileDownloadModalOpen = (updateRequest) => {
    setDownloadOpen(true);
    setCurrentDownloadRequest(updateRequest);
  };

  const handleFileDownloadModalClose = () => {
    setDownloadOpen(false);
    setCurrentDownloadRequest("");
  };

  //Menu List
  const list = (anchor) => (
    <Box
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {[
          "Update CRM",
          "Weather Test",
          "Update Weather",
          "Update PFSoftware",
        ].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              onClick={(e) => {
                SetMenu(index);
              }}
            >
              <ListItemIcon>
                {text === "Update CRM" ? <BrowserUpdatedIcon /> : <></>}
                {text === "Weather Test" ? <WbSunnyIcon /> : <></>}
                {text === "Update Weather" ? <BrowserUpdatedIcon /> : <></>}
                {text === "Update PFSoftware" ? <BrowserUpdatedIcon /> : <></>}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  //Get Node Status
  const StatusUpdate = () => {
    axios({
      method: "GET",
      url: "api/node_status",
    })
      .then(function (response) {
        setStatus(response.data.status);
      })
      .catch(function (error) {
        console.error(error.response.data);
      });
  };

  //Update the local Database by making a call to the CRM
  const DBUpdate = () => {
    axios({
      method: "GET",
      url: "api/update_now",
    })
      .then(function (response) {
        GetLastDBUpdate();
      })
      .catch(function (error) {
        console.error(error.response.data);
      });
  };

  //Get the last date the local database was updated
  const GetLastDBUpdate = () => {
    axios({
      method: "GET",
      url: "api/get_last_CRM_Update",
    })
      .then(function (response) {
        setLastCRMUpdate(response.data.lastUpdated);
      })
      .catch(function (error) {
        console.error(error.response.data);
      });
  };

  //Get the last date the local weather was updated
  const GetLastWeatherUpdate = () => {
    axios({
      method: "GET",
      url: "api/get_last_Weather_Update",
    })
      .then(function (response) {
        setLastWeatherUpdate(response.data.lastUpdated);
      })
      .catch(function (error) {
        console.error(error.response.data);
      });
  };

  //Get a test image to see if the weather service is working.
  const GetTestWeather = () => {
    axios({
      method: "GET",
      url: "api/test_get_image",
    })
      .then(function () {
        //console.log(response.data)
        //var urlCreator = window.URL || window.webkitURL;
        //var imageUrl = urlCreator.createObjectURL(response.data);
        //document.querySelector("#image").src = imageUrl;
        window.open("api/test_get_image");
        //const file = new Blob([response.data], { type: "image/png" });
        //Build a URL from the file
        //const fileURL = URL.createObjectURL(file);
        //document.querySelector("img").src = fileURL;
        //Open the URL on new Window
        //const pdfWindow = window.open();
        //pdfWindow.location.href = fileURL;
        //console.log(fileURL)
      })
      .catch(function (error) {
        console.error(error.response.data);
      });
  };

  //Tell the server to check for pufferfish software updates.
  const UpdatePFSoftware = () => {
    axios({
      method: "GET",
      url: "api/update_pfsoftware_now",
    })
      .then(function (response) {
        GetAvailableUpdates();
      })
      .catch(function (error) {
        console.error(error.response.data);
      });
  };

  //Get the last date the local software was updated
  const GetLastSoftwareDate = () => {
    axios({
      method: "GET",
      url: "api/getlastupdate_pfsoftware",
    })
      .then(function (response) {
        setLastPFSWpdate(response.data);
      })
      .catch(function (error) {
        console.error(error.response.data);
      });
  };

  //Tell the server to request new weather datasets
  const UpdateServerWeather = () => {
    axios({
      method: "GET",
      url: "api/update_weather_now",
    })
      .then(function (response) {
        setLastWeatherUpdate(response.data);
      })
      .catch(function (error) {
        console.error(error.response.data);
      });
  };

  //Get local pufferfish software available
  const GetAvailableUpdates = () => {
    axios({
      method: "GET",
      url: "api/get_available_updates",
    })
      .then(function (response) {
        //const dataResponseType = typeof response.data;
        setAvailableUpdates(response.data);
      })
      .catch(function (error) {
        console.error(error.response.data);
      });
  };

  //Download the software provided
  const DownloadUpdate = (productId, licenceKey, version) => {
    setDownloading(true);
    axios({
      method: "GET",
      url:
        "api/get_update?ProductID=" +
        productId +
        "&LicenceKey=" +
        licenceKey +
        "&VersionNumber=" +
        version,
      responseType: "blob",
      onDownloadProgress(progressEvent) {
        let percentCompleted = Math.floor(progressEvent.progress * 100);
        console.log(percentCompleted);
        setDownloadProgress(percentCompleted);
      },
    })
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", productId + "_" + version + ".zip");
        document.body.appendChild(link);
        setDownloading(false);
        link.click();
      })
      .catch(function (error) {
        setDownloading(false);
        console.error(error.response.data);
      });
  };

  //Pull the product id and licence key from the download string.
  const FileCheck = (file, licenceKey) => {
    var parts = file.split("_");
    const product = parts[0];
    const version = parts[1];
    DownloadUpdate(product, licenceKey, version);
    setDownloadOpen(false);
    setDownloadProgress(0);
  };

  //Download the log.txt file
  const DownloadLogs = () => {
    axios({
      method: "GET",
      url: "api/download_logs",
      responseType: "blob",
      onDownloadProgress(progressEvent) {
        //alert(progressEvent.progress);
        let percentCompleted = Math.floor(progressEvent.progress * 100);
        setDownloadProgress(percentCompleted);
      },
    })
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "log.txt");
        document.body.appendChild(link);
        setDownloading(false);
        link.click();
      })
      .catch(function (error) {
        setDownloading(false);
        console.error(error.response.data);
      });
  };

  //Get the front end version from the server
  const GetFrontEndVersion = () => {
    axios({
      method: "GET",
      url: "api/get_appVersion",
    })
      .then(function (response) {
        setVersion(response.data);
      })
      .catch(function (error) {
        console.error(error.response.data);
      });
  };

  useEffect(() => {
    GetFrontEndVersion();
    StatusUpdate();
    GetLastDBUpdate();
    GetLastSoftwareDate();
    GetLastWeatherUpdate();
    GetAvailableUpdates();
    return () => {};
  }, [status, lastCRMUpdate, availableUpdates, lastPFSWUpdate]);

  return (
    <div className="App">
      {!token ? (
        <Login setToken={setToken} />
      ) : (
        <div>
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" sx={{ backgroundColor: "Black" }}>
              <Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  aria-haspopup="true"
                  onClick={toggleDrawer("left", true)}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  PufferServices
                </Typography>
              </Toolbar>

              {["left"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                  >
                    {list(anchor)}
                  </Drawer>
                </React.Fragment>
              ))}
            </AppBar>
          </Box>
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <div>
              Server Status:{" "}
              <p
                style={
                  status === "Running" ? { color: "Green" } : { color: "Red" }
                }
              >
                {status}
              </p>
            </div>
            <div>
              Last CRM Update: <p style={{ color: "Green" }}>{lastCRMUpdate}</p>
            </div>
            <div>
              Last Weather Update:{" "}
              <p style={{ color: "Green" }}>{lastWeatherUpdate}</p>
            </div>
            <div>
              Last PFSoftware Update:{" "}
              <p style={{ color: "Green" }}>{lastPFSWUpdate}</p>
            </div>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{ mt: 4, mb: 2, paddingTop: 5 }}
                variant="h6"
                component="div"
              >
                Latest Software Updates Available
              </Typography>
              {downloading ? (
                <Box sx={{ position: "absolute", zIndex: 1, left: "50%" }}>
                  <CircularProgress
                    variant="determinate"
                    value={downloadProgress}
                    size={80}
                    thickness={4}
                  />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="h5"
                      component="div"
                      color="text.secondary"
                    >
                      {downloadProgress}%
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <></>
              )}
              <List>
                {availableUpdates.length === 0 ? "No Updates Available" : <></>}
                {availableUpdates.map((update) => (
                  <ListItem sx={{ cursor: "pointer" }}>
                    <ListItemIcon>
                      <FolderIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={update}
                      onClick={() => handleFileDownloadModalOpen(update)}
                    />
                  </ListItem>
                ))}
                <Dialog
                  fullScreen={fullScreen}
                  open={fileDownloadOpen}
                  onClose={() => handleFileDownloadModalClose()}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Download " + updateRequest + "?"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Please enter your licence key.
                    </DialogContentText>
                    <TextField
                      id="outlined-basic"
                      type="password"
                      label="key"
                      onChange={(e) => {
                        setInput(e.target.value);
                      }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      autoFocus
                      onClick={() => handleFileDownloadModalClose()}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => FileCheck(updateRequest, currentInput)}
                      autoFocus
                    >
                      Download
                    </Button>
                  </DialogActions>
                </Dialog>
              </List>
            </Grid>
          </Box>

          <Paper
            elevation={3}
            sx={{
              maxWidth: 800,
              padding: 5,
              marginLeft: 100,
              maxHeight: 250,
              overflow: "auto",
            }}
          >
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => DownloadLogs()}
            >
              Download Log
            </Button>
            <Typography>Log</Typography>
            <Logs />
          </Paper>
          <Typography
            sx={{ bottom: 0, right: 0, position: "absolute", zIndex: 1 }}
          >
            {frontVersion}{" "}
          </Typography>
        </div>
      )}
    </div>
  );
}
