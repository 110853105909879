/*LogGetter Gets and returns the server log.
 * @author Adam Kale @ Pufferfish Limited.
 */
import React, { useEffect, useState } from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";

export default function LogGetter() {
  const [logs, setLogs] = useState([""]);

  //Get log as array from server and split it to render
  const GetLogs = () => {
    axios({
      method: "GET",
      url: "api/get_logs",
    })
      .then(function (response) {
        var splitLog = response.data.split("[");
        setLogs(splitLog);
      })
      .catch(function (error) {
        console.error(error.response.data);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      GetLogs();
    }, 3 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      {logs.map((log) => (
        <Typography>{log}</Typography>
      ))}
    </div>
  );
}
